import type { JSX } from 'react';
import { CenteredText as CenteredTextComponent } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type CenteredTextProps = SliceComponentProps<Content.CenteredTextSlice>;

const CenteredText = ({ slice }: CenteredTextProps): JSX.Element => (
  <CenteredTextComponent {...slice} />
);

export default CenteredText;
